const showdown = require('showdown');

const FilterService = {

    truncate(text, length, clamp) {
        clamp = clamp || '...';
        let node = document.createElement('div');
        node.innerHTML = text;
        let content = node.textContent;
        return content.length > length ? content.slice(0, length) + clamp : content;
    },

    textCompile(text, options) {
        const converter = new showdown.Converter()
        if(options) {
            converter.setOption('emoji', options.emoji)
            converter.setOption('openLinksInNewWindow', options.openLinksInNewWindow)
        }
        return converter.makeHtml(text)
    },

    rewriteUrl(url) {
        return url.replaceAll(' ', '-')
            .replaceAll('ü', 'ue')
            .replaceAll('ä', 'ae')
            .replaceAll('ö', 'oe')
            .toLowerCase()
    },

    getRewriteUrl(url) {
        return url.replaceAll('-', ' ')
            .replaceAll('ue', 'ü')
            .replaceAll('ae', 'ä')
            .replaceAll('oe', 'ö')
    }
}

export default FilterService;
